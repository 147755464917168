var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pending-form__desktop"},[_c('p',{staticClass:"mt-3 text-left pending-forms__title"},[_vm._v("Formularios por llenar")]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pending-forms__container-pending"},[(_vm.listNoResponse && _vm.listNoResponse.length == 0)?[_c('v-alert',{staticClass:"ml-4 forms__alert-pending",staticStyle:{"height":"40px","width":"100%"},attrs:{"dense":"","text":"","type":"info"}},[_c('b',[_vm._v("No tiene formularios pendientes")])])]:_vm._l((_vm.listNoResponse),function(data){return _c('v-col',{key:data.id,staticClass:"pt-1",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mx-2 mt-0 mb-0 pending-forms__hover-card ",staticStyle:{"border-radius":"15px !important"},attrs:{"width":"270","input-value":_vm.active,"depressed":"","rounded":"","elevation":"0","outlined":""}},[_c('v-card-title',[_c('v-layout',{attrs:{"justify-center":""}},[_c('img',{attrs:{"width":"100","src":_vm.origin + '/icon/formulariosv2.svg'}})])],1),_c('v-card-text',{staticClass:"d-flex justify-center",staticStyle:{"height":"60px"}},[_c('p',{staticClass:"d-flex justify-center pending-forms__card-title",staticStyle:{"font-size":"19px"}},[_vm._v(" "+_vm._s(_vm.firstLetter(data.title.toLowerCase()))+" ")])]),_c('v-card-actions',{staticClass:"pt-0 mt-3 mb-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticStyle:{"color":"#fff"},style:({
                      'background-color': hover ? '#324CA1' : '#466BE3',
                    }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.ieDetails(data)}}},[_vm._v(" Contestar ")])]}}],null,true)})],1)],1)],1)})],2)],1)],1),_c('div',{staticClass:"mb-t-pending"},[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{staticClass:"mb-0 pb-0 mt-0 mb-0 pl-2"},[_c('p',{staticClass:"text-title-sm pt-3 pb-3 pl-0 mb-0"},[_vm._v(" Formularios Pendientes ")])]),(_vm.listNoResponse && _vm.listNoResponse.length == 0)?_c('v-alert',{staticClass:"mb-7 mx-4 forms__alert-pending",attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" No tiene formularios pendientes ")]):_vm._e(),_c('v-slide-group',{attrs:{"multiple":"","show-arrows":"","prev-icon":"mdi-chevron-left-circle-outline","next-icon":"mdi-chevron-right-circle-outline"}},_vm._l((_vm.listNoResponse),function(data){return _c('v-slide-item',{key:data.id,staticStyle:{"min-width":"10px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-2 border-control-card-p mt-0",staticStyle:{"border-radius":"8px","border":"1px solid #466be3"},attrs:{"input-value":active,"active-class":"purple white--text","depressed":"","rounded":"","elevation":"0","width":"250"},on:{"click":toggle}},[_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"8"}},[_c('p',{staticClass:"text-p-sm-blue text-1-lines mb-0 mt-3",on:{"click":function($event){return _vm.ieDetails(data)}}},[_vm._v(" "+_vm._s(data.title)+" ")])]),_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"4"}},[_c('img',{staticClass:"pt-2",staticStyle:{"width":"50px"},attrs:{"src":_vm.origin + '/icon/formularios-1.svg',"alt":"formularios"}})])],1)],1)]}}],null,true)})}),1)],1)],1),_c('div',{staticClass:"mb-sm-pending-form"},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"mb-0 pb-0 mt-0 pt-0 mb-0 pl-2"},[_c('p',{staticClass:"text-title-sm pt-0 pb-3 pl-0 mb-0"},[_vm._v(" Formularios Pendientes ")])]),_c('v-col',{staticClass:"mb-10 pb-0 mt-0 mb-0 pl-0 pr-0",attrs:{"cols":"12"}},[(_vm.listNoResponse && _vm.listNoResponse.length == 0)?_c('v-alert',{staticClass:"mb-7 mx-2 forms__alert-pending",attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" No tiene formularios pendientes ")]):_vm._e(),_c('v-slide-group',{attrs:{"multiple":"","show-arrows":"","prev-icon":"mdi-chevron-left-circle-outline","next-icon":"mdi-chevron-right-circle-outline"}},_vm._l((_vm.listNoResponse),function(data){return _c('v-slide-item',{key:data.id,staticStyle:{"min-width":"10px","width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-2 border-control-card-p mt-0",staticStyle:{"border-radius":"8px","border":"1px solid #466be3"},attrs:{"input-value":active,"active-class":"purple white--text","depressed":"","rounded":"","elevation":"0","width":"250"},on:{"click":toggle}},[_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"8"}},[_c('p',{staticClass:"text-p-sm-blue text-1-lines mb-0 mt-3",on:{"click":function($event){return _vm.ieDetails(data)}}},[_vm._v(" "+_vm._s(data.title)+" ")])]),_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"4"}},[_c('img',{staticClass:"pt-2",staticStyle:{"width":"50px"},attrs:{"src":_vm.origin + '/icon/formularios-1.svg',"alt":"formularios"}})])],1)],1)]}}],null,true)})}),1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.all}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#3957B9","indeterminate":""}},[_vm._v(" Cargando... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }