var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isMobile)?_c('div',{staticClass:"completed-forms__desktop"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pl-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('p',{staticClass:"mt-3 text-left completed-forms__title"},[_vm._v(" Formularios diligenciados ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"#afb0b0"}},[_vm._v(" mdi-magnify ")])]},proxy:true}],null,false,964573562),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 completed-forms__table ",attrs:{"search":_vm.search,"loading":_vm.loading.table,"items-per-page":10,"headers":_vm.headers,"items":_vm.listForms,"footer-props":{ itemsPerPageOptions: [5, 10] }},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"mt-3 forms__alert-completed",attrs:{"text":"","dense":"","type":"info"}},[_c('b',[_vm._v("No se encontraron resultados.")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-3 forms__alert-completed",attrs:{"text":"","dense":"","type":"info"}},[_c('b',[_vm._v("No se encontró información.")])])]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"#214484","height":5,"indeterminate":"","stream":""}})]},proxy:true},{key:"item.documentName",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.documentName)+" ")])])]}},{key:"item.publicationDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.publicationDate)+" ")])])]}},{key:"item.signatureDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.signatureDate)+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticStyle:{"color":"#fff"},style:({
                'background-color': hover ? '#324CA1' : '#466BE3',
              }),attrs:{"block":"","rounded":"","small":""},on:{"click":function($event){return _vm.ieDetails2(item)}}},[_vm._v(" Ver ")])]}}],null,true)})]}}],null,false,380788956)})],1)],1):_c('div',{staticClass:"forms-completed__tablet"},[_c('v-row',{staticClass:"completed__block-tablet"},[_c('v-col',{staticClass:"mb-0 pb-0 pt-0 mt-0 mb-0 pl-2"},[_c('p',{staticClass:"text-title-sm pt-0 pb-2 pl-0 mb-0"},[_vm._v(" Formularios completados ")])]),(_vm.listForms == null || _vm.listForms.length == 0)?_c('v-alert',{staticClass:"my-4 mx-4",attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" No ha respondido ningún formulario ")]):_vm._e(),_vm._l((_vm.listForms),function(data){return [_c('v-col',{key:data.id},[_c('v-card',{staticClass:"border-control-card-p",staticStyle:{"width":"auto"},attrs:{"outlined":"","elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('img',{staticClass:"mt-3 ml-4",staticStyle:{"width":"50px"},attrs:{"src":_vm.origin + '/icon/respuestas.svg',"alt":"formularios"}})]),_c('v-col',{staticStyle:{"text-align":"initial"},attrs:{"cols":"9"}},[_c('p',{staticClass:"ml-8 mb-0 mt-2 text-c-sm-blue text-title-1"},[_c('strong',[_vm._v(" "+_vm._s(data.title)+" ")])]),_c('p',{staticClass:"ml-8",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(data.fecha))])]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"2"}},[_c('v-card-actions',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"btn-main mb-2",attrs:{"rounded":"","block":"","dark":""},on:{"click":function($event){return _vm.ieDetails2(data)}}},[_vm._v(" Ver ")])],1)],1)],1)],1)],1)]})],2)],1),_c('v-overlay',{attrs:{"value":_vm.loading.all}},[_c('v-progress-circular',{attrs:{"size":120,"color":"#3957B9","indeterminate":""}},[_vm._v(" Cargando... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }